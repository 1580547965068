.wrapper {
    display: flex;
    align-items: stretch;
}

#sidebar {
    min-width: 250px;
    max-width: 250px;
    min-height: 100vh;
}

#sidebar.active {
    margin-left: -250px;
}

a[data-toggle="collapse"] {
    position: relative;
}

a[aria-expanded="false"]::before,
a[aria-expanded="true"]::before {
    content: '\e259';
    display: block;
    position: absolute;
    right: 20px;
    font-family: 'Glyphicons Halflings';
    font-size: 0.6em;
}

a[aria-expanded="true"]::before {
    content: '\e260';
}

.dr-page .dr-alert {
    border: 1px solid transparent;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 15px;
}

.dr-page .dr-alert-warning {
    background-color: #fcf8e3;
    border-color: #faebcc;
    color: #8a6d3b;
}

.dr-page .dr-alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
}

/* FILTER BOX - START */

.dr-page .dr-filterbox .panel-heading {
    padding: 0;
}

.dr-page .dr-filterbox .panel-heading {
    padding: 10px;
    width: 100%;
    display: table;
    text-align: left;
    font-size: 16px;
}

.dr-page .dr-filterbox .panel-heading.panel-collapsedin {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.dr-page .dr-filterbox .panel-heading .header-text {
    display: table-cell;
    line-height: 30px;
}

.dr-page .dr-filterbox .panel-heading .header-text svg {
    margin-right: 8px;
}

.dr-page .dr-filterbox .panel-heading .header-buttons {
    text-align: right;
}

.dr-page .dr-filterbox .panel-heading .header-buttons button {
    max-width: 140px;
    width: 100%;
}

.dr-page .dr-filterbox .dr-selector .Select--multi .Select-value {
    font-size: 0.7em;
}

/* FILTER BOX - END */

.dr-external-col {
    padding-left: 0;
    padding-right: 0;
}

.dr-filters strong,
.dr-selector strong {
    font-size: 12px;
}

.dr-filters strong {
    padding: 0 15px
}

.dr-filters .btn {
    font-size: 14px;
    padding: 4px 12px 4px 12px;
}

.dr-page .dr-dimension-head .btn {
    font-size: 11.8px;
    padding: 6px 8px;
}

.dr-filters .filter-grid.table {
    margin-bottom: 0px;
}

.dr-selector {
    margin-bottom: 10px;
}

.dr-dimension-head .Select-input,
.dr-selector .Select-input {
    height: 28px;
}

.dr-dimension-head .Select-value,
.dr-selector .Select-value {
    line-height: 16px;
    font-size: 12px;
    margin-top: 3px;
    margin-bottom: 2px;
}

.dr-dimension-head .Select-control,
.dr-selector .Select-control {
    height: 28px;
    overflow: auto
}

.dr-dimension-head .Select-option,
.dr-selector .Select-option {
    font-size: 12px;
    padding: 5px 10px;
}

.dr-dimension-head .Select--single>.Select-control .Select-value,
.dr-selector .Select--single>.Select-control .Select-value {
    line-height: 22px;
    font-size: 12px;
}

.dr-selector .Select-placeholder {
    font-size: 12px;
    line-height: 30px;
}

.dr-dimension-head {
    display: table;
    position: relative;
    width: 100%;
}

.dr-dimension-head .dr-dimension-left {
    display: table-cell;
    position: relative;
    width: 100%;
    vertical-align: bottom;
}

.dr-dimension-head .dr-dimension-right {
    display: table-cell;
    float: right;
    vertical-align: bottom;
    white-space: nowrap;
}

.dr-selector .Select-menu-outer,
.dr-dimension-head .Select-menu-outer {
    z-index: 5;
}

.dr-selector .date-select-wrapper {
    display: table;
    width: 100%;
}

.dr-selector .date-select-wrapper .InputFromTo {
    display: table;
    width: 100%;
}

.dr-selector .date-select-wrapper .DayPickerInput,
.dr-selector .date-select-wrapper .InputFromTo-to {
    display: table-cell;
    width: 50%;
}

.dr-selector .date-select-wrapper .DayPickerInput-Overlay {
    z-index: 5;
}

.date-select-wrapper .dates-restart-btn {
    font-size: 12px;
    line-height: 22px;
    padding: 3px 9px;
}

/* taskDaySummary-values*/

.taskDaySummary-value {
    text-align: right;
}

.taskDaySummary-value:before {
    font-family: "FontAwesome";
    font-weight: 800;
    position: absolute;
    top: 0;
    left: 2px;
    font-size: 21px;
    z-index: 1;
    background-color: #fff;
    border-radius: 50%;
    line-height: 12px;
    font-size: 22px;
    top: 6px;
}

.taskDaySummary-checked-value:before {
    content: "\f058";
    color: #28a745;
    margin-left: 25px;
}

.taskDaySummary-warning-value:before {
    content: "\f06a";
    color: #ffc107;
    margin-left: 25px;
}

.taskDaySummary-alert-value:before {
    content: "\f057";
    color: #dc3545;
    margin-left: 25px;
}

/* END taskDaySummary-values*/

/* tm-summary-values*/

.tm-summary-value {
    text-align: right;
}

.tm-summary-value:before {
    font-family: "FontAwesome";
    font-weight: 800;
    position: absolute;
    top: 0;
    left: 2px;
    font-size: 21px;
    z-index: 1;
    background-color: #fff;
    border-radius: 50%;
    line-height: 12px;
    font-size: 22px;
    top: 6px;
}

.tm-summary-checked-value:before {
    content: "\f058";
    color: #28a745;
}

.tm-summary-warning-value:before {
    content: "\f06a";
    color: #ffc107;
}

.tm-summary-alert-value:before {
    content: "\f057";
    color: #dc3545;
}

/* END tm-summary-values*/

/* ag-grid*/

.dr-page .ag-floating-bottom {
    font-weight: bold;
}

/* favs */
.spinCenter,
.spin-centered {
    margin: 0 auto;
}

.spin-inline {
    display: inline-block;
}

.btn-fav-apply {
    color: #337ab7;
    text-decoration: none;
    background-color: transparent;
    box-sizing: border-box;
    cursor: pointer;
    text-align: center;
}

.btn-fav-apply-disabled {
    color: #b9b9b9;
    text-decoration: none;
    background-color: transparent;
    box-sizing: border-box;
    text-align: center;
}

.btn-fav-apply:active,
.btn-fav-apply:hover {
    outline: 0;
}

.btn-fav-apply:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.btn-fav-apply:hover,
.btn-fav-apply:focus {
    color: #23527c;
    text-decoration: underline;
}

ol.fav-save {
    margin-bottom: 0;
}

.de-alert-wrapper {
    margin-top: 15px;
    margin-bottom: 20px;
}

.de-alert-warning {
    background-color: #fcf8e3;
    border-color: #faebcc;
    color: #8a6d3b;
}

.de-alert {
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 15px;
}

.de-alert ul {
    margin-bottom: 0
}

.dr-page .ag-body-viewport {
    overflow-x: auto;
}

/*TitleComponentFAVS*/

.title-row .fav-col {
    padding-top: 12px;
}

.title-row .fav-btn {
    color: #337ab7;
    text-decoration: underline;
    background-color: transparent;
    box-sizing: border-box;
    cursor: pointer;
    text-align: center;
    border: none
}

.title-row .fav-btn[class*=-disabled] {
    color: #b9b9b9;
    cursor: not-allowed;
}

.fav-btn:active,
.fav-btn:hover {
    outline: 0;
}

.fav-btn:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.fav-btn:hover,
.fav-btn:focus {
    color: #23527c;
    text-decoration: underline;
}

/*END favs*/

/*maps */

.maps-loading-data.wrapper-loader {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 100%;
    z-index: 10;
}

.maps-loading-data.text-loader {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    z-index: 10;
    background-color: #fff;
    border: 1px solid #BDC3C7;
    color: #000;
    padding: 16px;
    border-radius: 2px;
    box-shadow: none;
    font: 400 12px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.maps-loading-spinner {
    margin-top: 150px;
    text-align: center;
    text-align: -moz-center;
    text-align: -webkit-center;
}

.status-icon-cell {
    text-align: center;
    padding-right: 0;
    padding-left: 0;
}

.popover-legend .popover-title {
    font-weight: 600;
    font-size: 13px;
    color: #6e7687;
}

.popover-legend .popover-content {
    font-size: 12px;
    font-weight: 600;
}

.btn-popover-legend {
    font-weight: 600;
    letter-spacing: .03em;
    font-size: 12px;
    min-width: 2.375rem;
    line-height: 1.84615385;
}

.transition-width {
    transition: width .2s ease-out;
    -webkit-transition: width .2s ease-out;
    -moz-transition: width .2s ease-out;
    -o-transition: width .2s ease-out;
}

.col-xs-0,
.col-sm-0,
.col-md-0,
.col-lg-0 {
    position: relative;
    width: 0;
    padding-right: 0;
    padding-left: 0;
    height: 0;
}

.dr-catalog-assortment-grid .ag-header-cell {
    padding-left: 6px;
    padding-right: 6px;
}

.dr-catalog-assortment-grid .ag-header-cell .ag-header-cell-text {
    white-space: initial;
    line-height: 12px;
    height: auto;
    place-self: center;
}

.dr-catalog-assortment-grid .ag-header-cell .ag-header-cell-resize::after {
    height: 34px;
}

.dr-floating-filter-grid .ag-cell-label-container .ag-header-cell-label {
    width: 100%;
}

@media (max-width: 767px) {
    #sidebar {
        margin-left: -250px;
    }

    #sidebar.active {
        margin-left: 0;
    }

    .title-row .fav-col {
        padding-top: 0px;
    }
}

@media (max-width: 380px) {
    .dr-page .dr-filterbox .panel-heading .header-buttons button {
        max-width: 110px;
    }
}

@media print {
    body {
        -webkit-print-color-adjust: exact;
    }
}

.custom-tooltip {
    position: relative;
    width: 100%;
    overflow: visible;
}

.custom-tooltip .custom-tooltip-context {
    visibility: hidden;
    background-color: #fff;
    position: absolute;
    padding: 10px;
    z-index: 99999;
    top: 10px;
}

.custom-tooltip:hover .custom-tooltip-context {
    visibility: visible;
}

.move-header-cell {
    background-color: #f5f7f7;
    top: -25px;
}