body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.ag-body-viewport {
  overflow-x: hidden;
}

.highcharts-legend-item path,
.highcharts-legend-item rect,
.highcharts-legend [class^="highcharts-legend-nav-"] {
  display: none
}

.summary-charts .highcharts-legend-item path,
.summary-charts .highcharts-legend-item rect,
.summary-charts .highcharts-legend [class^="highcharts-legend-nav-"] {
  display: block
}

.min-110 {
  min-height: 110px;
}

.min-130 {
  min-height: 130px;
}